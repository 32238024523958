import { Link } from "@StarberryUtils";
import * as React from "react"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
} from "react-bootstrap"
import Select, { components } from "react-select";
import {navigate} from "gatsby";

import "./QuestionList.scss"
import secLogoBg1 from "../../../images/form-combined/sec-logo-bg1.svg"

import "animate.css/animate.css"
import ResaleForm from "../ResaleForm/ResaleForm"
import PrimaryMarket from "../PrimaryMarket/PrimaryMarket"
import OffPlanProjects from "../OffPlanProjects/OffPlanProjects"
import LeaseToOwn from "../LeaseToOwn/LeaseToOwn"
import LandPlotLead from "../LandPlotLead/LandPlotLead"
import RentalListing from "../RentalListing/RentalListing"
import CommercialLease from "../CommercialLease/CommercialLease"
import LandOwners from "../LandOwners/LandOwners"
import {useLocation} from "@reach/router"

import {PreQualificationQst} from "../../../queries/common_use_query"

const QuestionList = (props) => {
  const loc = useLocation();
 

  const[prequal_qst, setPrequal_Qst] = React.useState();

  const {loading, error, data} = PreQualificationQst();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageName = queryParams.get("page");


  React.useEffect(()=>{
    data && setPrequal_Qst(data.preQualifiedQuestionsAnswer);
  },[data])
  
  var options = []; 
  if(props.prevPath === "why-sell-or-let-commercial-property-with-us" || props.prevPath === "commercial-property-guide"){
    options = [
      { value: '/pre-qualified/commercial-sale', label: 'Commercial Sale' },
      { value: '/pre-qualified/commercial-lease', label: 'Commercial Lease' },
    ]
  }if(pageName === "mortgage") {
    options = [
      { value: '/pre-qualified/secondary-market', label: 'Secondary Market' },
      { value: '/pre-qualified/primary-market', label: 'Primary Market' },
      { value: '/pre-qualified/offplan-projects', label: 'Off Plan Projects' }, 
      { value: '/pre-qualified/land-plot', label: 'Land/Plot' }
    ]
  }else{
    options = [
      { value: '/pre-qualified/secondary-market', label: 'Secondary Market' },
      { value: '/pre-qualified/primary-market', label: 'Primary Market' },
      { value: '/pre-qualified/offplan-projects', label: 'Off Plan Projects' },
      { value: '/pre-qualified/lease-to-own', label: 'Lease to Own' },
      { value: '/pre-qualified/land-plot', label: 'Land/Plot' }
    ]
  }
  

  const selectCat = (e) => {
    var prevrootpath = props.prevState.prevPath;
    //console.log("select", e);
    props.clearFormState();
    navigate(e.value, {
      state: { prevrootpath },
    });
  }

  //console.log("pre data 1", prequal_qst)

  return (
    <React.Fragment>
      <div className="questions-list text-center">
        <img src={secLogoBg1} className="sec-logobg" />
        <Container>
          <Row>
            <Col>
              <a href="javascript:;" onClick={props.handleBack} className="back-link">
                {/* <i className="right-arrow"></i>Back to Property Listing */}
                <i className="right-arrow"></i>Go Back
              </a>
              {
                props.form_name ? (
                  <>
                     <div className="animated">
                      {/* <h2>Pre Qualification Checklist for <br />{props.form_name}</h2> */}
                      <h1>Pre Qualification Checklist</h1>
                     </div>

                     <div className="animated">
                      {(props.form_type === "secondary-market" || props.form_type === "resale" || props.form_type === "commercial-sale") && <ResaleForm form_values={props.form_values} nextClick={props.nextClick} handleChange={props.handleChange} form_type={props.form_type} prequal_qst={prequal_qst}/>}
                      
                      {(props.form_type === "primary-market" || props.form_type === "payment-plans") && <PrimaryMarket form_values={props.form_values} nextClick={props.nextClick} handleChange={props.handleChange} form_type={props.form_type} prequal_qst={prequal_qst}/>}

                      {(props.form_type === "offplan-projects") && <OffPlanProjects form_values={props.form_values} nextClick={props.nextClick} handleChange={props.handleChange} form_type={props.form_type} prequal_qst={prequal_qst}/>}

                      {(props.form_type === "lease-to-own") && <LeaseToOwn form_values={props.form_values} nextClick={props.nextClick} handleChange={props.handleChange} form_type={props.form_type} prequal_qst={prequal_qst}/> }

                      {(props.form_type === "rental-listing") && <RentalListing form_values={props.form_values} nextClick={props.nextClick} handleChange={props.handleChange} form_type={props.form_type}/> }

                      {(props.form_type === "land-plot") && <LandPlotLead form_values={props.form_values} nextClick={props.nextClick} handleChange={props.handleChange} form_type={props.form_type}/>}

                      {(props.form_type === "commercial-lease") && <CommercialLease form_values={props.form_values} nextClick={props.nextClick} handleChange={props.handleChange} form_type={props.form_type} prequal_qst={prequal_qst}/>}

                      {(props.form_type === "land-owners") && <LandOwners form_values={props.form_values} nextClick={props.nextClick} handleChange={props.handleChange} form_type={props.form_type}/>}
                     </div>
                  </>
                ) : (
                   <div className="animated">
                      <h1>Pre Qualification Checklist</h1>

                      <div className="pre_qualification_form">
                        <div className="select-group">
                          <Select 
                            options={options} 
                            placeholder={"Select option"} 
                            className="select-opt__control" 
                            onChange={selectCat} 
                          />
                        </div>
                      </div>
                      
                   </div>
                )
              }
              
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default QuestionList
