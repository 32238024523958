import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useCallback } from "react"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
} from "react-bootstrap"
import RangeSlider from "../../../images/form-combined/range-slider.png"
import ImgPerson from "../../../images/person.jpg"
import "./ApplicationForm.scss"

import { propTypes } from "react-bootstrap/esm/Image"
import PreQualificationForm  from "../../forms/pre-qualification"

import {removeNonNumeric}  from "../../common/utils"

const ApplicationForm = (props) => {
  const [value, setValue] = useState("");
  const[form_values, setFormState] = React.useState(props.form_values);
  const[form_post_params, setFormPostParams] = React.useState(props.form_values);

  useEffect(()=>{
    setFormState(props.form_values);

    if(props.form_type === "secondary-market"){

      var pre_qual_qst = "contact[Listing_Type__c]=Sale&contact[Purchase_Type__c]="+form_values.cash_mortgage+"&contact[Property_Search_Journey__c]="+form_values.home_buying_journey+"&contact[Decision_Maker__c]="+(form_values.buying_process === "Yes" ? true : false)+"&contact[ListingPrice_pb_min__c]="+removeNonNumeric(form_values.budget_home_min)+"&contact[Price_max__c]="+removeNonNumeric(form_values.budget_home_max)+"&contact[Decision_Timeframe__c]="+form_values.decision_time+"&contact[pba__ContactType_pb__c]=Investor, End user";

      if(form_values.buyer_owner === "First Time Buyer"){
        pre_qual_qst += "&contact[First_Time_Buyer__c]=true";
      }

      if(form_values.buyer_owner === "Existing Property Owner"){
        pre_qual_qst += "&contact[Existing_Owner__c]=true&contact[Area1__c]="+form_values.crm_property_location+"&contact[Building_Tower__c]="+form_values.crm_tower_building+"&contact[Property_Type__c]="+form_values.crm_property_type+"&contact[Unit__c]="+form_values.crm_plot_number+"&contact[Bedroom__c]="+form_values.crm_no_of_bedrrom+"&contact[Unit_sqm__c]="+form_values.crm_property_size+"&contact[Expected_Current_Rent_QR__c]="+removeNonNumeric(form_values.crm_monthly_rent)+"&contact[Receive_Monthly_CMA_Report__c]="+(form_values.monthly_report === "Yes" ? true : false);
      }
       
      setFormPostParams(pre_qual_qst);

    } else if(props.form_type === "primary-market"){

      var pre_qual_qst = "contact[RecordTypeId]=0124v0000018HKKAA2&contact[Listing_Type__c]=Sale&contact[Cheque_Book__c]="+(form_values.cheque_book === "Yes" ? true : false)+"&contact[Bank_Statement__c]="+(form_values.receiving_income === "Yes" ? true : false)+"&contact[Property_Search_Journey__c]="+form_values.home_search_journey+"&contact[Decision_Maker__c]="+(form_values.buying_process === "Yes" ? true : false)+"&contact[Decision_Timeframe__c]="+form_values.decision_time+"&contact[Credit_Bureau_Score__c]="+form_values.credit_score+"&contact[Down_Payment__c]="+form_values.down_payment.replace(' %','')+"&contact[Self_Employed__c]="+(form_values.self_employed === "Yes" ? true : false)+"&contact[pba__ContactType_pb__c]=Investor, End user";


      setFormPostParams(pre_qual_qst);

    } else if(props.form_type === "offplan-projects"){

      var pre_qual_qst = "contact[RecordTypeId]=0124v0000018HKKAA2&contact[Listing_Type__c]=Sale&contact[Cheque_Book__c]="+(form_values.cheque_book === "Yes" ? true : false)+"&contact[Bank_Statement__c]="+(form_values.receiving_income === "Yes" ? true : false)+"&contact[Decision_Maker__c]="+(form_values.buying_process === "Yes" ? true : false)+"&contact[Decision_Timeframe__c]="+form_values.decision_time+"&contact[ListingPrice_pb_min__c]="+removeNonNumeric(form_values.budget_home_min)+"&contact[Price_max__c]="+removeNonNumeric(form_values.budget_home_max)+"&contact[Credit_Bureau_Score__c]="+form_values.credit_score+"&contact[Self_Employed__c]="+(form_values.self_employed === "Yes" ? true : false)+"&contact[pba__ContactType_pb__c]=Investor, End user";

      setFormPostParams(pre_qual_qst);

    } else if(props.form_type === "lease-to-own"){

      var pre_qual_qst = "contact[RecordTypeId]=0124v0000018HKPAA2&contact[Listing_Type__c]=Rent&contact[Cheque_Book__c]="+(form_values.cheque_book === "Yes" ? true : false)+"&contact[Bank_Statement__c]="+(form_values.receiving_income === "Yes" ? true : false)+"&contact[Property_Search_Journey__c]="+form_values.home_search_journey+"&contact[Decision_Maker__c]="+(form_values.buying_process === "Yes" ? true : false)+"&contact[Decision_Timeframe__c]="+form_values.decision_time+"&contact[Credit_Bureau_Score__c]="+form_values.credit_score+"&contact[Down_Payment__c]="+form_values.down_payment.replace(' %','')+"&contact[Self_Employed__c]="+(form_values.self_employed === "Yes" ? true : false)+"&contact[Expected_Current_Rent_QR__c]="+removeNonNumeric(form_values.monthly_rent)+"&contact[pba__ContactType_pb__c]=Tenant";
      
      setFormPostParams(pre_qual_qst);

    } else if(props.form_type === "rental-listing"){

      var pre_qual_qst = "contact[RecordTypeId]=0124v0000018HKPAA2&contact[Listing_Type__c]=Rent&contact[QID__c]="+(form_values.qid === "Yes" ? true : false)+"&contact[Cheque_Book__c]="+(form_values.cheque_book === "Yes" ? true : false)+"&contact[Property_Search_Journey__c]="+form_values.property_search_journey+"&contact[Bank_Statement__c]="+(form_values.salary_certificate === "Yes" ? true : false)+"&contact[Decision_Maker__c]="+(form_values.buying_process === "Yes" ? true : false)+"&contact[Decision_Timeframe__c]="+form_values.decision_time+"&contact[ListingPrice_pb_min__c]="+removeNonNumeric(form_values.budget_home_min)+"&contact[Price_max__c]="+removeNonNumeric(form_values.budget_home_max)+"&contact[pba__ContactType_pb__c]=Tenant";
      
      setFormPostParams(pre_qual_qst);
      
    } else if(props.form_type === "land-plot"){

      var pre_qual_qst = "contact[RecordTypeId]=0124v0000018HOqAAM&contact[Listing_Type__c]=Sale&contact[Purchase_Type__c]="+form_values.cash_mortgage+"&contact[Land_buying_journey__c]="+form_values.land_buying_journey+"&contact[Decision_Maker__c]="+(form_values.buying_process === "Yes" ? true : false)+"&contact[ListingPrice_pb_min__c]="+removeNonNumeric(form_values.budget_home_min)+"&contact[Price_max__c]="+removeNonNumeric(form_values.budget_home_max)+"&contact[Decision_Timeframe__c]="+form_values.decision_time+"&contact[Permit_you_are_looking_for__c]="+form_values.kind_of_permit+"&contact[pba__ContactType_pb__c]=Investor, End user";
      
      setFormPostParams(pre_qual_qst);
      
    } else if(props.form_type === "commercial-sale"){

      
      var pre_qual_qst = "contact[Listing_Type__c]=Sale&contact[Purchase_Type__c]="+form_values.cash_mortgage+"&contact[Property_Search_Journey__c]="+form_values.home_buying_journey+"&contact[Decision_Maker__c]="+(form_values.buying_process === "Yes" ? true : false)+"&contact[ListingPrice_pb_min__c]="+removeNonNumeric(form_values.budget_home_min)+"&contact[Price_max__c]="+removeNonNumeric(form_values.budget_home_max)+"&contact[Decision_Timeframe__c]="+form_values.decision_time+"&contact[pba__ContactType_pb__c]=Investor, End user";

      if(form_values.buyer_owner === "First Time Buyer"){
        pre_qual_qst += "&contact[First_Time_Buyer__c]=true";
      }

      if(form_values.buyer_owner === "Existing Property Owner"){
        pre_qual_qst += "&contact[Existing_Owner__c]=true&contact[Area1__c]="+form_values.crm_property_location+"&contact[Building_Tower__c]="+form_values.crm_tower_building+"&contact[Property_Type__c]="+form_values.crm_property_type+"&contact[Unit__c]="+form_values.crm_plot_number+"&contact[Bedroom__c]="+form_values.crm_no_of_bedrrom+"&contact[Unit_sqm__c]="+form_values.crm_property_size+"&contact[Expected_Current_Rent_QR__c]="+removeNonNumeric(form_values.crm_monthly_rent)+"&contact[Receive_Monthly_CMA_Report__c]="+(form_values.monthly_report === "Yes" ? true : false);
      }
       
      setFormPostParams(pre_qual_qst);
      
    } else if(props.form_type === "commercial-lease"){
      
      var pre_qual_qst = "contact[RecordTypeId]=0124v0000018HKAAA2&contact[Listing_Type__c]=Rent&contact[Property_Search_Journey__c]="+form_values.property_search_journey+"&contact[Decision_Maker__c]="+(form_values.buying_process === "Yes" ? true : false)+"&contact[Decision_Timeframe__c]="+form_values.decision_time+"&contact[business_or_startup__c]="+form_values.established_business+"&contact[Business_Type__c]="+form_values.type_business+"&contact[Commercial_Registration__c]="+(form_values.commercial_registration === "Yes" ? true : false)+"&contact[Industry_Sector__c]="+form_values.industry_sector.replace('&', 'and')+"&contact[Number_of_Employees__c]="+form_values.employee_accomodate+"&contact[pba__ContactType_pb__c]=Tenant";

      setFormPostParams(pre_qual_qst);
      
    }
    
    

  },[props.form_values])

  const mobileHandler = e => {
    let val = e.target.value
    let phoneno = /^[0-9\b]+$/
    if (val === "" || val.match(phoneno)) {
      setValue(val);
      props.handleChange(e);
    } else {

    }
  }

  //console.log("form_post_params", form_post_params);

  console.log("prevState", props.prevState);

  return (
    <React.Fragment>
      <div className="personal-application text-center">
        <Container>
          <Row>
            <Col>
              <a href="javascript:;" onClick={props.handleBack} className="back-link">
                <i className="right-arrow"></i>Go Back
              </a>

              <h2 className="form-title">
                 <div className="animated">
                  Now we’ll need a few personal details to complete your
                  application.
                 </div>
              </h2>
               <div className="animated">
                
                <PreQualificationForm container_classname="application-form" classNames={"text-left"} form_values={props.form_values} form_name={props.form_name} form_type={props.form_type} form_post_params={form_post_params} prevClick={props.prevClick} clearFormState={props.clearFormState} sublisting_type={props.sublisting_type} property_id={props.property_id} url={props.prevState.prevrootpath} />

                {/* <Form action="javascript:;" className="application-form text-left" method="post" onSubmit={props.handleSubmit}>

                  <input type="hidden" name="form_name" value={props.form_name} />
                  <input type="hidden" name="form_type" value={props.form_type} />
                  <input type="hidden" name="to_email_id" value={""} />

                  <Form.Group>
                    <Form.Label className="annual-txt">Your Name</Form.Label>
                    <Form.Control type="text" placeholder="Christopher Rowe" name="your_name" onChange={props.handleChange} value={form_values.your_name} required={true} />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="annual-txt">Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      maxlength="10"
                      value={form_values.phone_no}
                      name="phone_no"
                      onChange={mobileHandler}
                      placeholder="07818495093"
                      required={true}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="annual-txt">
                      Email Address
                    </Form.Label>
                    <Form.Control
                      type="email"
                      onChange={props.handleChange}
                      value={form_values.email_id}
                      name="email_id"
                      placeholder="chrisr@starberry.tv"
                      required={true}
                    />
                  </Form.Group>
                  
                  <div className="btn-wrapper d-md-flex justify-content-between">
                    <a href="javascript:;" className="btn btn-step" onClick={props.prevClick}>
                      <span>Previous Step</span>
                    </a>
                    <Button variant="primary" type="submit">
                      <span>Submit</span>
                    </Button>
                  </div>
                </Form>
               */}
               </div> 
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ApplicationForm
