import { Link } from "@StarberryUtils";
import React,{useState, useEffect} from "react"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
} from "react-bootstrap"
import SelectBox from "../../Home/SelectBox/SelectBox"

const ResaleForm = (props) => {

    const[form_values, setFormState] = React.useState(props.form_values);

    useEffect(()=>{
        setFormState(props.form_values);
    },[props.form_values])

  var monthly_report = ["Yes", "No"];

  const proTypeOptions = [{ value: '', label: 'Select Type', type: "dropdown", name: "crm_property_type" }] 
  
  props.prequal_qst && props.prequal_qst.Property_Type.length > 0 && props.prequal_qst.Property_Type.map(item => proTypeOptions.push({ value: item.Property_Type, label: item.Property_Type, type: "dropdown", name: "crm_property_type" })); 

  
  //console.log("proTypeOptions", proTypeOptions, props.prequal_qst);

  return (
    <>
        <p className="question">
            Are You Willing To Receive our Monthly CMA Report? <br /><i>(If Yes, Kindly enter your property details as per the below-required fields in order to receive the relevant CMA report.)</i>
        </p>
        <div className="q-option option-step3">
            {
                monthly_report.map((item,index) =>{
                    return(
                        <Form.Group>
                            <Form.Check
                            inline
                            label={item}
                            type="radio"
                            name="monthly_report"
                            value={item}
                            onChange={props.handleChange}
                            checked={item === form_values.monthly_report ? true : false}
                            />
                        </Form.Group>
                    )
                })
            }
        </div>

        
        {
            form_values.monthly_report === "Yes" && (
                <div className="pt-0">

                
                    <p className="question">
                        Enter Property Location
                    </p>
                    <div className="q-option input-step">
                        <Form.Group>
                            <Form.Control
                                type="text"
                                onChange={props.handleChange}
                                value={form_values.crm_property_location}
                                name="crm_property_location"
                                required={true}
                            />
                        </Form.Group>
                    </div>

                    <p className="question">
                        Tower/Building
                    </p>
                    <div className="q-option input-step">
                        <Form.Group>
                            <Form.Control
                            type="text"
                            onChange={props.handleChange}
                            value={form_values.crm_tower_building}
                            name="crm_tower_building"
                            required={true}
                            />
                        </Form.Group>

                    </div>

                    <p className="question">
                        Property Type
                    </p>
                    <div className="q-option input-step ext_sel_box">
                        <Form.Group>
                            {/* <Form.Control
                                type="text"
                                onChange={props.handleChange}
                                value={form_values.crm_property_type}
                                name="crm_property_type"
                                required={true}
                            /> */}

                            <SelectBox placeHolder="Select Type" name="crm_property_type" value={form_values.crm_property_type} options={proTypeOptions} handleChange={props.handleChange} required={true}/>
                        </Form.Group>

                    </div>

                    <p className="question">
                        Unit / Plot Number:
                    </p>
                    <div className="q-option input-step">
                        <Form.Group>
                            <Form.Control
                            type="text"
                            onChange={props.handleChange}
                            value={form_values.crm_plot_number}
                            name="crm_plot_number"
                            required={true}
                            />
                        </Form.Group>
                    </div>

                    <p className="question">
                        Number of Bedrooms:
                    </p>
                    <div className="q-option input-step">
                        <Form.Group>
                            <Form.Control
                            type="text"
                            onChange={props.handleChange}
                            value={form_values.crm_no_of_bedrrom}
                            name="crm_no_of_bedrrom"
                            required={true}
                            />
                        </Form.Group>
                    </div>

                    <p className="question">
                        Property Size (Sqm):
                    </p>
                    <div className="q-option input-step">
                        <Form.Group>
                            <Form.Control
                            type="text"
                            onChange={props.handleChange}
                            value={form_values.crm_property_size}
                            name="crm_property_size"
                            required={true}
                            />
                        </Form.Group>
                    </div>

                    <p className="question">
                        Monthly Rent in QR (If Applicable):
                    </p>
                    <div className="q-option input-step">
                        <Form.Group>
                            <Form.Control
                            type="text"
                            onChange={props.handleChange}
                            value={form_values.crm_monthly_rent}
                            name="crm_monthly_rent"
                            required={true}
                            placeholder="QR 1000"
                            />
                        </Form.Group>
                    </div>
             </div>
            )
        }
        
    </>
              
  )
}

export default ResaleForm
