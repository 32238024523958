import { Link } from "@StarberryUtils";
import React,{useState, useEffect} from "react"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
} from "react-bootstrap"
import SelectBox from "../../Home/SelectBox/SelectBox"
import MortgageBuyer from "../ResaleForm/MortgageBuyer"
import CashBuyer from "../ResaleForm/CashBuyer"

const ResaleForm = (props) => {
  const[form_values, setFormState] = React.useState(props.form_values);

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const myRef = React.createRef();

  const handleSubmit = (event) => {
      const form = event.currentTarget;
  
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        setShowerror(true);
        setValidated(true);
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
      } else {
          event.preventDefault();
          setShowerror(false);
          setValidated(false);
          props.nextClick(form_values);
      }      
  }

  useEffect(()=>{
    setFormState(props.form_values);
  },[props.form_values])

  var cash_mortgage = ["Cash", "Mortgage"];

  return (
    <>
    <div ref={myRef} />
    {showerror && <div className="alert alert-danger err_maxwdth">
    Highlighted fields are required.
    </div>}
    <Form action="javascript:;" className="question-form" method="post" onSubmit={handleSubmit} noValidate validated={validated}>

        <>
            <p className="question">Are you a Cash Buyer or Mortgage Buyer?</p>
            <div className="q-option option-step1">
                {
                    cash_mortgage.map((item,index) =>{
                        return(
                        <Form.Group>
                            <Form.Check
                            inline
                            label={item}
                            name="cash_mortgage"
                            type="radio"
                            value={item}
                            onChange={props.handleChange}
                            required
                            checked={item === form_values.cash_mortgage ? true : false}
                            required={true}
                            />
                        </Form.Group>
                        )}
                    )
                }
            </div>
            
            {form_values.cash_mortgage === "Cash" && <CashBuyer {...props} handleChange={props.handleChange} form_values={props.form_values} /> }
            {form_values.cash_mortgage === "Mortgage" && <MortgageBuyer {...props} handleChange={props.handleChange} form_values={props.form_values} />}

            {props.form_values.decision_time && 
            <div className="col-12">
                {/* <Button variant="primary" type="button"  onClick={()=>props.nextClick(form_values)}><span>Next Step</span></Button> */}
                <Button variant="primary" type="submit"><span>Next Step</span></Button>
            </div>}
        </>

    </Form>
  </>  
  )
}

export default ResaleForm