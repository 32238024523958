import { Link } from "@StarberryUtils";
import React,{useState, useEffect} from "react"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
} from "react-bootstrap"
import SelectBox from "../../Home/SelectBox/SelectBox"

const ResaleForm = (props) => {

    const[form_values, setFormState] = React.useState(props.form_values);

    useEffect(()=>{
        setFormState(props.form_values);
    },[props.form_values])
  
  var kind_of_permit = ["Apartment Building", "Hotel Permit", "Commercial & Retails Permit", "Residential Villa", "Public Building","Workshop/Factory"];
  var down_payment = ["0 - 30 %", "30 - 70 %", "70 - 90 %"];
  var home_buying_journey = ["Just Started Looking at Listings", "Touring Properties", "Making or Made offers"];
  var decision_time = ["1-30 Days", "30-90 Days", "90-180 Days", "180-Above"];
  var buying_process = ["Yes", "No"];
  var self_employed = ["Yes", "No"];
  var receiving_income = ["Yes", "No"];
  var credit_score = ["Excellent: 741- 850", "Good: 631- 740", "Fair: 521-630", "Poor: 411- 520", "Very Poor: 350-410"];

  var journey_title = props.form_type === "land-plot" ? "Where are you in your Land-buying journey" : "Where are you in your home-buying journey?";
  var journey_name = props.form_type === "land-plot" ? "land_buying_journey" : "home_buying_journey";
  var journey_values = props.form_type === "land-plot" ? form_values.land_buying_journey : form_values.home_buying_journey;

  return (
    <>  
        {
            props.form_type === "land-plot" ? <>
                <p className="question">
                    What Kind of permit you are looking to obtain?
                </p>
                <div className="q-option option-step2">
                    {
                        kind_of_permit.map((item,index) =>{
                            return(
                                <Form.Group>
                                    <Form.Check
                                    inline
                                    label={item}
                                    type="radio"
                                    name="kind_of_permit"
                                    value={item}
                                    onChange={props.handleChange}
                                    checked={item === form_values.kind_of_permit ? true : false}
                                    required={true}
                                    />
                                </Form.Group>
                            )
                        })
                    }
                </div>

                {
                    form_values.kind_of_permit && <>
                        <p className="question">How much do you have for a down payment? <br />Tip: most banks require a 30% down payment.</p>
                        <div className="q-option option-step4">
                            {
                                down_payment.map((item,index) =>{
                                    return(
                                        <Form.Group>
                                            <Form.Check
                                            inline
                                            label={item}
                                            type="radio"
                                            name="down_payment"
                                            value={item}
                                            onChange={props.handleChange}
                                            checked={item === form_values.down_payment ? true : false}
                                            required={true}
                                            />
                                        </Form.Group>
                                    )
                                })
                            }
                        </div>
                    </>
                }
            </> : <>
                <p className="question">How much do you have for a down payment? <br />Tip: most banks require a 30% down payment.</p>
                <div className="q-option option-step4">
                    {
                        down_payment.map((item,index) =>{
                            return(
                                <Form.Group>
                                    <Form.Check
                                    inline
                                    label={item}
                                    type="radio"
                                    name="down_payment"
                                    value={item}
                                    onChange={props.handleChange}
                                    checked={item === form_values.down_payment ? true : false}
                                    required={true}
                                    />
                                </Form.Group>
                            )
                        })
                    }
                </div>
            </>
        }
        

        
        {
            form_values.down_payment && <>
                <p className="question">What is your Credit Bureau Score? It’s ok to Estimate for now.</p>
                <div className="q-option option-step2">
                    {
                        credit_score.map((item,index) =>{
                            return(
                                <Form.Group>
                                    <Form.Check
                                    inline
                                    label={item}
                                    type="radio"
                                    name="credit_score"
                                    value={item}
                                    onChange={props.handleChange}
                                    checked={item === form_values.credit_score ? true : false}
                                    required={true}
                                    />
                                </Form.Group>
                            )
                        })
                    }
                </div>
            </>
        }
        

        {
            form_values.credit_score && <>
                <p className="question">Were you receiving consistent income for the last six months? <br />And it reflects on your bank account?</p>
                <div className="q-option option-step3">
                    {
                        receiving_income.map((item,index) =>{
                            return(
                                <Form.Group>
                                    <Form.Check
                                    inline
                                    label={item}
                                    type="radio"
                                    name="receiving_income"
                                    value={item}
                                    onChange={props.handleChange}
                                    checked={item === form_values.receiving_income ? true : false}
                                    required={true}
                                    />
                                </Form.Group>
                            )
                        })
                    }
                </div>
            </>
        }
        
        {
            form_values.receiving_income && <>
                <p className="question">
                    {journey_title}            
                </p>
                <div className="q-option option-step2">
                    {
                        home_buying_journey.map((item,index) =>{
                            return(
                                <Form.Group>
                                    <Form.Check
                                    inline
                                    label={item}
                                    type="radio"
                                    name={journey_name}
                                    value={item}
                                    onChange={props.handleChange}
                                    checked={item === journey_values ? true : false}
                                    required={true}
                                    />
                                </Form.Group>
                            )
                        })
                    }
                </div>
            </>
        }

        {
            journey_values && <>
                <p className="question">
                    Are you a decision maker in this buying process?
                </p>
                <div className="q-option option-step3">
                    {
                        buying_process.map((item,index) =>{
                            return(
                                <Form.Group>
                                    <Form.Check
                                    inline
                                    label={item}
                                    type="radio"
                                    name="buying_process"
                                    value={item}
                                    onChange={props.handleChange}
                                    checked={item === form_values.buying_process ? true : false}
                                    required={true}
                                    />
                                </Form.Group>
                            )
                        })
                    }
                </div>
            </>
        }

        {
            form_values.buying_process && <>
                <p className="question">What is your decision time frame?</p>
                <div className="q-option option-step4">
                    {
                        decision_time.map((item,index) =>{
                            return(
                                <Form.Group>
                                    <Form.Check
                                    inline
                                    label={item}
                                    type="radio"
                                    name="decision_time"
                                    value={item}
                                    onChange={props.handleChange}
                                    checked={item === form_values.decision_time ? true : false}
                                    required={true}
                                    />
                                </Form.Group>
                            )
                        })
                    }
                </div>
            </>
        }       

        {
            form_values.decision_time && <>
                <p className="question">
                Are you Self Employed?
                </p>
                <div className="q-option option-step3">
                    {
                        self_employed.map((item,index) =>{
                            return(
                                <Form.Group>
                                    <Form.Check
                                    inline
                                    label={item}
                                    type="radio"
                                    name="self_employed"
                                    value={item}
                                    onChange={props.handleChange}
                                    checked={item === form_values.self_employed ? true : false}
                                    required={true}
                                    />
                                </Form.Group>
                            )
                        })
                    }
                </div> 
            </>
        }
    </>
              
  )
}

export default ResaleForm