import { Link } from "@StarberryUtils";
import React,{useState, useEffect} from "react"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
} from "react-bootstrap"
import SelectBox from "../../Home/SelectBox/SelectBox"

const ResaleForm = (props) => {

    const[form_values, setFormState] = React.useState(props.form_values);
    const [validated, setValidated] = useState(false);
    const [showerror, setShowerror] = useState(false);
    const myRef = React.createRef();

    const handleSubmit = (event) => {
        const form = event.currentTarget;
    
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
          setShowerror(true);
          setValidated(true);
          myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
        } else {
            event.preventDefault();
            setShowerror(false);
            setValidated(false);
            props.nextClick(form_values);
        }      
    }

    useEffect(()=>{
        setFormState(props.form_values);
    },[props.form_values])
  
    var down_payment = ["0 - 30 %", "30 - 70 %", "70 - 90 %"];
    var home_search_journey = ["Just Started Looking at Listings", "Touring Properties", "Making or Made offers"];
    var decision_time = ["1-30 Days", "30-90 Days", "90-180 Days", "180-Above"];
    var buying_process = ["Yes", "No"];
    var self_employed = ["Yes", "No"];
    var receiving_income = ["Yes", "No"];
    
    var credit_score = [];
    props.prequal_qst && props.prequal_qst.Credit_Bureau_Score.length > 0 && props.prequal_qst.Credit_Bureau_Score.map(item => credit_score.push(item.Credit_Bureau_Score));

    var cheque_book = ["Yes", "No"];

  return (
    <>
    <div ref={myRef} />
    {showerror && <div className="alert alert-danger err_maxwdth">
    Highlighted fields are required.
    </div>}
    <Form action="javascript:;" method="post" onSubmit={handleSubmit} noValidate validated={validated}>
        <p className="question">
            Do You Have a Cheque Book?
        </p>
        <div className="q-option option-step3">
            {
                cheque_book.map((item,index) =>{
                    return(
                        <Form.Group>
                            <Form.Check
                            inline
                            label={item}
                            type="radio"
                            name="cheque_book"
                            value={item}
                            onChange={props.handleChange}
                            checked={item === form_values.cheque_book ? true : false}
                            required={true}
                            />
                        </Form.Group>
                    )
                })
            }
        </div>
        
        {
            form_values.cheque_book && <>
            <p className="question">Were you receiving consistent income for the last six months? <br />And it reflects on your bank account?</p>
            <div className="q-option option-step3">
                {
                    receiving_income.map((item,index) =>{
                        return(
                            <Form.Group>
                                <Form.Check
                                inline
                                label={item}
                                type="radio"
                                name="receiving_income"
                                value={item}
                                onChange={props.handleChange}
                                class="form-control"
                                checked={item === form_values.receiving_income ? true : false}
                                required={true}
                                />
                            </Form.Group>
                        )
                    })
                }
            </div>
            </>
        }
        
        {
            form_values.receiving_income && <>
                <p className="question">What is your Credit Bureau Score? It’s ok to Estimate for now.</p>
                <div className="q-option option-step2">
                    {
                        credit_score.map((item,index) =>{
                            return(
                                <Form.Group>
                                    <Form.Check
                                    inline
                                    label={item}
                                    type="radio"
                                    name="credit_score"
                                    value={item}
                                    onChange={props.handleChange}
                                    checked={item === form_values.credit_score ? true : false}
                                    required={true}
                                    />
                                </Form.Group>
                            )
                        })
                    }
                </div>
            </>
        }
        
        {
            form_values.credit_score && <>
                <p className="question">How much do you have for a down payment? <br />Tip: Downpayment starts from 15%.</p>
                <div className="q-option option-step4">
                    {
                        down_payment.map((item,index) =>{
                            return(
                                <Form.Group>
                                    <Form.Check
                                    inline
                                    label={item}
                                    type="radio"
                                    name="down_payment"
                                    value={item}
                                    onChange={props.handleChange}
                                    checked={item === form_values.down_payment ? true : false}
                                    required={true}
                                    />
                                </Form.Group>
                            )
                        })
                    }
                </div>
            </>
        }

        {
            form_values.down_payment && <>
                <p className="question">
                Are you Self Employed?
                </p>
                <div className="q-option option-step3">
                    {
                        self_employed.map((item,index) =>{
                            return(
                                <Form.Group>
                                    <Form.Check
                                    inline
                                    label={item}
                                    type="radio"
                                    name="self_employed"
                                    value={item}
                                    onChange={props.handleChange}
                                    checked={item === form_values.self_employed ? true : false}
                                    required={true}
                                    />
                                </Form.Group>
                            )
                        })
                    }
                </div>
            </>
        }

        {
            form_values.self_employed && <>
                <p className="question">
                    Where are you in your home-search journey?
                </p>
                <div className="q-option option-step2">
                    {
                        home_search_journey.map((item,index) =>{
                            return(
                                <Form.Group>
                                    <Form.Check
                                    inline
                                    label={item}
                                    type="radio"
                                    name="home_search_journey"
                                    value={item}
                                    onChange={props.handleChange}
                                    checked={item === form_values.home_search_journey ? true : false}
                                    required={true}
                                    />
                                </Form.Group>
                            )
                        })
                    }
                </div>
            </>
        }

        {
            form_values.home_search_journey && <>
                <p className="question">
                    Are you a decision maker in this buying process?
                </p>
                <div className="q-option option-step3">
                    {
                        buying_process.map((item,index) =>{
                            return(
                                <Form.Group>
                                    <Form.Check
                                    inline
                                    label={item}
                                    type="radio"
                                    name="buying_process"
                                    value={item}
                                    onChange={props.handleChange}
                                    checked={item === form_values.buying_process ? true : false}
                                    required={true}
                                    />
                                </Form.Group>
                            )
                        })
                    }
                </div>
            </>
        }
        
        {
            form_values.buying_process && <>
                <p className="question">What is your decision time frame?</p>
                <div className="q-option option-step4">
                    {
                        decision_time.map((item,index) =>{
                            return(
                                <Form.Group>
                                    <Form.Check
                                    inline
                                    label={item}
                                    type="radio"
                                    name="decision_time"
                                    value={item}
                                    onChange={props.handleChange}
                                    checked={item === form_values.decision_time ? true : false}
                                    required={true}
                                    />
                                </Form.Group>
                            )
                        })
                    }
                </div> 
            </>
        }

        {
            form_values.decision_time && <>
                <div className="col-12">
                    {/* <Button variant="primary" type="submit"  onClick={()=>props.nextClick(form_values)}><span>Next Step</span></Button> */}
                    <Button variant="primary" type="submit"><span>Next Step</span></Button>
                </div>     
            </>
        } 

        </Form>
    </>
              
  )
}

export default ResaleForm