import React, {useState, useEffect, useRef} from "react"
import { Helmet } from "react-helmet";
import {navigate} from "gatsby";
import queryString from 'query-string';

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import PreQualification from "../../components/PreQualification/QuestionList/QuestionList";
import ApplicationForm from "../../components/PreQualification/ApplicationForm/ApplicationForm";
import {addCommas, removeNonNumeric} from "../../components/common/utils";

// styles

// markup
const PreQualified = ( props ) => {
  let path_name = (props.location.pathname).split('/');
  let get_url = path_name[path_name.length-1];

  let prev_path_name = props.location.state && props.location.state.prevPath && (props.location.state.prevPath).split('/');
  let prev_url = prev_path_name && prev_path_name[prev_path_name.length-1];

  if(prev_url === ""){
    prev_url = prev_path_name[prev_path_name.length-2];
  }


  let query_params = queryString.parse(props.location.search);
  var property_id = query_params.pid ? query_params.pid : '';

  const[isNext, setNext] = useState(false);
  const myRef = useRef();
  const[form_values, setFormState] = useState({});

  var form_heading = '';
  var form_post_fields = '';
  var sublisting_type = '';
  var meta_desc = "The Pearl Gates can assist you with Pre Qualification and checking your eligibility to help you identify the right properties in the right locations.";

  // useEffect(() =>{
  //   if(get_url === "secondary-market" || get_url === "resale"){
  //     setFormState({...form_values, buyer_owner: "First Time Buyer", cash_mortgage:"Mortgage Buyer"});
  //   }
  // },[get_url])

  const handleChange = (e) => {
    if(e.type === "dropdown"){
        const value = e.value.trimStart();
        setFormState({
            ...form_values,
            [e.name]: value
        });
    } else{
        var value = e.target.value;
        if(e.target.name === "budget_home_min" || e.target.name === "budget_home_max" || e.target.name === "monthly_rent"){
          value = addCommas(removeNonNumeric(e.target.value));
        }
        setFormState({
            ...form_values,
            [e.target.name]: value
        });
    }
  }
  
  const clearFormState = () => {
    setFormState("");
  }

  // console.log ('&&&&&', data)
  //console.log("get_url",get_url);

  const nextClick = (form_values) => {    
    setNext(true);
    typeof window !== undefined && window.scrollTo(0, 0);
  }

  const prevClick = () => {
    setNext(false);
    typeof window !== undefined && window.scrollTo(0, 0)
  }

  const handleSubmit = () => {
    //console.log("form_values 2", form_values);
  }
  


  const handleBack = () => window.history.back();


  if(get_url === "secondary-market" || get_url === "resale"){
    form_heading = "Resale/Secondary Market";    
    meta_desc = "Want to buy your first property in Qatar or you are already an investor and own properties but looking for better returns? Check out Pearl Gates."
    sublisting_type = "Resale";
  } else if(get_url === "primary-market" || get_url === "payment-plans"){
    form_heading = "Primary Market"
    sublisting_type = "Primary Market";
  } else if(get_url === "commercial-sale"){
    form_heading = "Commercial Sale"
  } else if(get_url === "commercial-lease"){
    form_heading = "Commercial Lease"
  } else if(get_url === "offplan-projects"){
    form_heading = "Off Plan Listing"
    sublisting_type = "Off Plan";
  } else if(get_url === "lease-to-own"){
    form_heading = "Lease To Own"
    sublisting_type = "Lease To Own";
  } else if(get_url === "rental-listing"){
    form_heading = "Rental Listing"
    meta_desc = "The fastest way to find rented houses in Qatar is now at your fingertips! We provide you with information about pre-qualified tenants and other details for renting."
  } else if(get_url === "land-plot"){
    form_heading = "Land/Plot"
    meta_desc = "Know your pre qualification with The Pearl gates as we provide you with full information about your eligibility towards buying Land or Plot in Qatar."
  } else if(get_url === "land-owners"){
    form_heading = "Land Owners"
  } else{
    form_heading = ""
    meta_desc = "The Pearl Gates can assist you with Pre Qualification and checking your eligibility to help you identify the right properties in the right locations."
    //form_type = "land-plot"
    // if(typeof window != "undefined"){
    //   navigate('/pre-qualified/secondary-market');
    // }    
  }
  
  var form_type = get_url;

  // if(get_url && get_url === "pre-qualified"){
  //   form_type = form_type;
  // } else{
  //   form_type = get_url;
  // }

  // console.log ("xxxxx", props.location.state, props.location.prevrootpath)

  return (
    <Layout Layout={'Without_Banner'} classNames="Without_Banner">

      <SEO title={`Prequalified Form | ${form_heading}`} description={`${meta_desc}`} noindex={true} />
      
      <Helmet/>

      <div className="page-content pre_qualification">
        {
            isNext ? (
                <ApplicationForm prevClick={prevClick} handleSubmit={handleSubmit} form_values={form_values} handleChange={handleChange} form_name={form_heading} form_type={get_url} clearFormState={clearFormState} handleBack={handleBack} sublisting_type={sublisting_type} property_id={property_id} prevState={props.location.state} />
            ) : (
                <PreQualification form_name={form_heading} form_type={form_type} nextClick={nextClick} form_values={form_values} handleChange={handleChange} handleBack={handleBack} clearFormState={clearFormState} location={props.location} prevPath={prev_url ? prev_url : ''} prevState={props.location.state} sublisting_type={sublisting_type} property_id={property_id} />
            )   
        }        
      </div>

    </Layout>
  )
}

export default PreQualified