import { Link } from "@StarberryUtils";
import React,{useState, useEffect} from "react"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
} from "react-bootstrap"
import SelectBox from "../../Home/SelectBox/SelectBox"

const LandOwnersForm = (props) => {

    const[form_values, setFormState] = React.useState(props.form_values);

    const [validated, setValidated] = useState(false);
    const [showerror, setShowerror] = useState(false);
    const myRef = React.createRef();

    const handleSubmit = (event) => {
        const form = event.currentTarget;
    
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
          setShowerror(true);
          setValidated(true);
          myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
        } else {
            event.preventDefault();
            setShowerror(false);
            setValidated(false);
            props.nextClick(form_values);
        }      
    }


    useEffect(()=>{
        setFormState(props.form_values);
    },[props.form_values])


  var land_graded = ["Yes", "No"];
  var site_reports = ["Yes", "No"];
  var land_fully_entitled = ["Yes", "No"];
  var fees_settled = ["Yes", "No"];
  var improvements_made = ["Water", "Side Walks", "Swage", "Streetscapes", "Sidewalks Areas"];
  var professional_service_fees = ["Yes", "No"];
  

  return (
    <>
    <div ref={myRef} />
    {showerror && <div className="alert alert-danger err_maxwdth">
    Highlighted(*) fields are required.
    </div>}
    <Form action="javascript:;" method="post" onSubmit={handleSubmit} noValidate validated={validated}>
        <p className="question">
          Is your land fully entitled?
        </p>
        <div className="q-option option-step3">
            {
                land_fully_entitled.map((item,index) =>{
                    return(
                        <Form.Group>
                            <Form.Check
                            inline
                            label={item}
                            type="radio"
                            name="land_fully_entitled"
                            value={item}
                            onChange={props.handleChange}
                            checked={item === form_values.land_fully_entitled ? true : false}
                            required={true}
                            />
                        </Form.Group>
                    )
                })
            }
        </div>
        
        {form_values.land_fully_entitled && <>
            <p className="question">Do you have Site Reports?</p>
            <div className="q-option option-step3">
                {
                    site_reports.map((item,index) =>{
                        return(
                            <Form.Group>
                                <Form.Check
                                inline
                                label={item}
                                type="radio"
                                name="site_reports"
                                value={item}
                                onChange={props.handleChange}
                                checked={item === form_values.site_reports ? true : false}
                                required={true}
                                />
                            </Form.Group>
                        )
                    })
                }
            </div>
        </>
        }

        {form_values.site_reports && <>
            <p className="question">Has the land/development been graded?</p>
            <div className="q-option option-step3">
                {
                    land_graded.map((item,index) =>{
                        return(
                            <Form.Group>
                                <Form.Check
                                inline
                                label={item}
                                type="radio"
                                name="land_graded"
                                value={item}
                                onChange={props.handleChange}
                                checked={item === form_values.land_graded ? true : false}
                                required={true}
                                />
                            </Form.Group>
                        )
                    })
                }
            </div>
        </>
        }

        {form_values.land_graded && <>
            <p className="question">
            Have any improvements been made?
            </p>
            <div className="q-option option-step">
                {
                    improvements_made.map((item,index) =>{
                        return(
                            <Form.Group>
                                <Form.Check
                                inline
                                label={item}
                                type="radio"
                                name="improvements_made"
                                value={item}
                                onChange={props.handleChange}
                                checked={item === form_values.improvements_made ? true : false}
                                required={true}
                                />
                            </Form.Group>
                        )
                    })
                }
            </div>
        </>
        }

        {form_values.improvements_made && <>
            <p className="question">
            Are all impact fees settled?
            </p>
            <div className="q-option option-step3">
                {
                    fees_settled.map((item,index) =>{
                        return(
                            <Form.Group>
                                <Form.Check
                                inline
                                label={item}
                                type="radio"
                                name="fees_settled"
                                value={item}
                                onChange={props.handleChange}
                                checked={item === form_values.fees_settled ? true : false}
                                required={true}
                                />
                            </Form.Group>
                        )
                    })
                }
            </div>
        </>
        }

        {form_values.fees_settled && <>
            <p className="question">
            Professional Service Fees?
            </p>
            <div className="q-option option-step3">
                {
                    professional_service_fees.map((item,index) =>{
                        return(
                            <Form.Group>
                                <Form.Check
                                inline
                                label={item}
                                type="radio"
                                name="professional_service_fees"
                                value={item}
                                onChange={props.handleChange}
                                checked={item === form_values.professional_service_fees ? true : false}
                                required={true}
                                />
                            </Form.Group>
                        )
                    })
                }
            </div>
        </>
        }

        {form_values.professional_service_fees &&
            <div className="col-12">
                {/* <Button variant="primary" type="button"  onClick={()=>props.nextClick(form_values)}><span>Next Step</span></Button> */}
                <Button variant="primary" type="submit"><span>Next Step</span></Button>
            </div>   
        }   
        </Form>

    </>
              
  )
}

export default LandOwnersForm